<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'margin-desktop' : ''">
    <app-member-menu-bar></app-member-menu-bar>
    <v-snackbar class="pa-0" light timeout="-1" v-model="alertShow" width="fit-content" style="z-index:1000 !important;top:4%" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-row no-gutters class="layout-col-wrapper" style="" :class="$vuetify.breakpoint.md ? '' : ''">
      <v-col cols="2" md="2" class="bank-profile-side-panel mt-4 hidden-sm-and-down">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? (showPanel ? 8 : 10) : 12" class="bank-mid">
        <router-view></router-view>
      </v-col>
      <v-col v-if="showPanel" cols="2" md="2" class="bank-profile-side-panel hidden-sm-and-down mt-4">
        <app-bank-right-side-panel></app-bank-right-side-panel>
      </v-col>
    </v-row>
    <!--    <v-row v-else no-gutters>-->
    <!--      <v-col cols="1" class="bank-profile-side-panel hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'my-12' : 'my-4'">-->
    <!--        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>-->
    <!--      </v-col>-->
    <!--      <v-col cols="12">-->
    <!--        <router-view></router-view>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <app-referral class="hidden-md-and-down"></app-referral>
  </div>
</template>

<script>
import { uiHelper } from '@/util'
import { SESSION } from '@/constants/constants'
import AppBankAndProfileSidePanel from '@/components/layout/BankAndProfileSideMenu.vue'
import AppBankRightSidePanel from '@/components/layout/BankRightSideMenu.vue'
import { PAYMENT_MEMBER_BANK_ACCOUNT, PAYMENT_BANK } from '@/store/payment.module'
import { MEMBER_DETAIL } from '@/store/member.module'
import AppReferral from '@/components/member/referral'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppMemberMenuBar from '@/components/layout/MemberMenuBar.vue'

export default {
  name: 'bankLayout',
  components: {
    AppBankAndProfileSidePanel,
    AppMemberMenuBar,
    AppBankRightSidePanel,
    AppReferral
  },
  data: () => ({
    routeName: ROUTE_NAME,
    openNav: false,
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    showPanel() {
      const routeName = this.$route.name
      return routeName === 'deposit' || routeName === 'withdrawal'
    },
    eachWalletArr() {
      return _.orderBy(this.$store.state.member.walletBalance.wallets, 'isPrimary', 'desc')
    }
  },
  watch: {
    $route() {
      const routeName = this.$route.name
      this.showPanel === 'deposit' || routeName === 'withdrawal'
    }
  },
  created() {
    this.getMemberBankAccount()
    this.getBank()
    this.getMemberDetail()
  },
  methods: {
    getMemberDetail() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    getBank() {
      let obj = {
        bankName: '',
        bankCode: '',
        currency: uiHelper.getCurrency(),
        Page: '',
        ItemsPerpage: '',
        orderBy: '',
        order: ''
      }
      this.$store.dispatch(`${PAYMENT_BANK}`, {
        obj
      })
    },
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss">
.margin-desktop {
  margin: 0px 12%;
}
.layout-col-wrapper {
  height: 100%;
  margin-bottom: 16px !important;
}

.noGap {
  gap: 0px 5px !important;
}
.bankPage-navigationBar {
  display: flex;
  padding: 15px 15px;
  justify-content: start;
  gap: 10px 15px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0 0 0;
  .nav-item {
    text-transform: capitalize;

    .nav-btn {
      border-radius: 20px;
      background-color: transparent;
      &.active-page {
        background-color: var(--v-newMobilePrimary-base);
      }
    }
  }
}
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}
.desktop_card {
  border-radius: 10px !important;
  background-color: #fff !important;
  height: 100%;
  label {
    color: #3a3a3a;
  }
  .v-card__title {
    margin-left: 26px;
  }
  .icon-btn {
    &:hover {
      fill: #0c2aab !important;
      path {
        fill: #0c2aab !important;
      }
    }
  }
}
.bank-detail-card {
  border-radius: 10px !important;
  margin: 0px 40px;
  border: 1px solid var(--v-base) !important;
  font-size: 14px;
  .v-card__title {
    padding: 0px 40px;
  }
  label {
    color: #3a3a3a;
  }
  label.field-name {
    color: #acacac;
  }
  .v-label {
    font-size: 14px;
  }
}
.title_color--text.sub_balance {
  font-size: 16px;
}
.v-list-item__content.sub_balance {
  padding-top: 0 !important;
}
.bank-account-card {
  width: 100%;
  border: 2px solid #acacac !important;
  border-radius: 10px !important;
  &.inComplete {
    border: 2px solid var(--v-secondary_2-base) !important;
  }
}
.bank-balance {
  background-color: #121734 !important;
  height: 36px;
  .v-divider {
    height: 100%;
    display: inline-block;
    border-color: red;
    border-right: 5px solid var(--v-navigator_active-base) !important;
  }
  .balance-amount {
    min-width: 100px;
  }
}
.bank-page-form {
  padding: 0px 20px 25px;
  max-width: 600px;
  margin: auto;
}
.payment-method-list-container {
  padding-bottom: 20px;
}
.payment-method-list-item {
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 10px;
  .payment-method-list-item-button {
    background-image: linear-gradient(to bottom, #273270, #1c244f);
    letter-spacing: 0;
    min-width: 150px !important;
    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}
.bank-navigation-button {
  color: #9c9ff3 !important;
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}
.bank-page-title {
  border-bottom: 4px solid var(--v-title_color-base);
}
.bank-transaction-stepper {
  box-shadow: none;
  min-height: 350px;
}
.v-stepper.bank-transaction-stepper {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.deposit-page {
  min-height: 630px;
}
@media (max-width: 1500px) {
  .bank-profile-side-panel {
    min-height: 700px;
  }
}

@media (min-width: 959px) {
  .bank-mid {
    margin-top: 16px;
    //margin-bottom: 16px;
  }
}

@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .bank-page-title {
    font-size: 16px;
  }
  .bank-navigation-button {
    padding: 10px 20px !important;
    min-height: auto;
    height: auto;
  }
  .payment-method-list-item .payment-method-list-item-button {
    padding: 12px !important;
    min-width: 120px !important;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 959px) {
  .desktop_card {
    border-radius: unset !important;
    height: 100%;
    font-size: 14px;
    padding-top: 10px !important;
    label {
      color: #3a3a3a;
    }
    .v-card__title {
      padding: 20px 0px 0px 20px;
      margin-left: 12px;
      label {
        font-size: 18px !important;
      }
    }
  }
  .bank-detail-card {
    border-radius: 10px !important;
    margin: 0px;
    padding: 0px;
    border: 1px solid var(--v-base) !important;
    font-size: 14px;
  }
}
@media (max-width: 599px) {
  .v-list-item__content {
    padding: 0 !important;
  }
}
@media (max-width: 425px) {
}
</style>
